<template>
  <b-row class="ml-0">
    <b-col lg="12">

      <b-row>
        <b-col style="padding-left: 0px !important;">
          <div class="check-parts">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success justify-content-end">
              <input type="checkbox"
                :checked="isChecked"
                @change="$emit('selected', term.id, $event.target.checked)" />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer"></span>
          </div>
          <div style="max-width: 80%;" class="check-parts ml-4">
            <p class="link" @click.prevent="showTerm" v-html="text"></p>
          </div>

        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>


<style lang="scss" scoped>


.check-parts {
  float: left;
}
.link:hover {
  cursor: pointer;
}
.link {
  padding-top: 3px;
  color: #0080ff;
  text-decoration: underline;
}

.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
.termdescr {
  cursor: pointer;
}

.wizard-body {
  max-width: 1000px;
  width: 100%;
}

.has-success .kt-option .kt-option__label .kt-option__head .kt-option__title {
  color: #1dc9b7;
}

.has-error .kt-option .kt-option__label .kt-option__head .kt-option__title {
  color: #ffb822;
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'term-component',
  props: ['term'],
  emits: ['selected', 'showTerm'],
  components: {},
  mixins: [ toasts ],
  data() {
    return {
      isChecked: false,
      text: ''
    };
  },
  watch: {
    isChecked(newValue, oldValue) {
      this.$emit('selected', this.term.id, newValue);
    }
  },
  mounted() {
    this.text = this.term.descr;

    if (this.text === '' || this.text === undefined || this.text === null) {
      this.text = '&nbsp;';
    }

    var tag_start = this.text.indexOf('%');
    var tag_end = this.text.indexOf('%', tag_start + 1);

    var tag_str = this.text.substring(tag_start + 1, tag_end);

    var before = this.text.substring(0, tag_start);
    var after = this.text.substring(tag_end + 1);

    this.text =
      before +
      '<span style="color: #006dca; text-decoration: underline;">' +
      tag_str +
      '</span>' +
      after;
  },

  methods: {
    showTerm() {
      this.$emit('showTerm', this.term.id);
    },

  }
};
</script>
